import { createCustomStore } from '@emobg/web-utils';
import { external } from '@emobg/web-api-client';

const NAMESPACE = 'VehicleDamages';

const ACTIONS = {
  getVehicleDamagesReports: 'GET_VEHICLE_DAMAGES_REPORT',
};

const store = createCustomStore(({ runAsyncFlow }) => ({
  actions: {
    [ACTIONS.getVehicleDamagesReports]({ commit }, { vehicleUuid }) {
      runAsyncFlow(commit, {
        request: external.vehicleDamages.getVehicleDamagesReport,
        params: [vehicleUuid],
      });
    },
  },
}));

export default {
  store,
  NAMESPACE,
  ACTIONS,
};
