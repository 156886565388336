<template>
  <div class="DamageReportView">
    <ReportHeaderComponent />
    <div
      v-if="isLoaded"
      class="container"
    >
      <div class="row mt-4">
        <h1 class="col-12 mb-3">
          {{ $t('views.vehicle_damage_report.title') }}
        </h1>
        <div class="col-sm-12 col-md-6">
          <h2 class="mb-1">
            {{ vehicleModel }}
          </h2>
          <p>
            {{ vehicleLicensePlate }}
          </p>
          <span class="emobg-font-small emobg-color-ink">
            {{ `${$t('views.vehicle_damage_report.version')} : ${date}` }}
          </span>
          <div
            v-for="(damageImages, index) in report.vehicle.model.damageImages"
            :key="index"
            class="position-relative"
          >
            <DamageItemComponent
              :damages="report.vehicleDamages"
              :image="damageImages"
            />
          </div>
          <ReportFooterComponent :vehicle="report.vehicle" />
        </div>
      </div>
    </div>
    <div
      v-if="hasError"
      class="d-flex text-center justify-content-center align-items-center h-100 emobg-font-x-large"
    >
      {{ $t('notifications.whooops') }}
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import LoaderMixin from '@/mixins/Loader';
import { DATE_FORMAT, FALLBACK_MESSAGE, getValue } from '@emobg/web-utils';
import moment from 'moment/moment';
import { getVehicleModel } from '@/domains/Vehicle/helpers';
import { DamageItemComponent, ReportFooterComponent, ReportHeaderComponent } from './components';
import VehicleDamagesModule from './store/VehicleDamagesModule';

export default {
  name: 'DamageReportView',

  components: {
    DamageItemComponent,
    ReportFooterComponent,
    ReportHeaderComponent,
  },

  mixins: [
    LoaderMixin,
  ],
  setup() {
    const date = moment().format(DATE_FORMAT.carsharingDate);
    return {
      getVehicleModel,
      date,
    };
  },
  computed: {
    ...mapState(VehicleDamagesModule.NAMESPACE, {
      report: state => state.data,
      hasError: state => state.STATUS.ERROR,
      isLoaded: state => state.STATUS.LOADED,
    }),
    vehicleModel() {
      return getVehicleModel(getValue(this, 'report.vehicle', FALLBACK_MESSAGE.dash));
    },
    vehicleLicensePlate() {
      return getValue(this, 'report.vehicle.licensePlate', FALLBACK_MESSAGE.dash);
    },
  },

  beforeCreate() {
    const { NAMESPACE, store } = VehicleDamagesModule;
    this.$store.registerModule(NAMESPACE, store);
  },

  created() {
    this.$watch(vm => [vm.hasError, vm.isLoaded], ([hasError, isLoaded]) => {
      if (hasError || isLoaded) {
        this.setLoaderStatus(false);
      }
    });
    const vehicleUuid = this.$route.params.vehicleUUID;
    this.getVehicleDamagesReports({ vehicleUuid });
  },

  beforeDestroy() {
    this.$store.unregisterModule(VehicleDamagesModule.NAMESPACE);
  },

  methods: {
    ...mapActions(VehicleDamagesModule.NAMESPACE, {
      getVehicleDamagesReports: VehicleDamagesModule.ACTIONS.getVehicleDamagesReports,
    }),
  },
};
</script>

<style>
  .DamageReportView {
    -webkit-print-color-adjust: exact;
  }
</style>
