<template>
  <div class="DamageItemComponent">
    <DamagePointComponent
      v-for="(damage, index) in filteredDamages"
      :key="`damage-${index}`"
      :style="getStylesByDamage(damage)"
      class="center"
    />

    <img
      :alt="$t('views.vehicle_damage_report.damages')"
      :src="image.url"
      class="img-fluid"
    >
  </div>
</template>
<script>
import filter from 'lodash/filter';
import get from 'lodash/get';

import DamagePointComponent from './DamagePointComponent';

export default {
  name: 'DamageItemComponent',

  components: {
    DamagePointComponent,
  },

  props: {
    damages: {
      type: Array,
      required: true,
    },

    image: {
      type: Object,
      required: true,
    },
  },

  computed: {
    filteredDamages() {
      const image = get(this, 'image.name');

      return filter(this.damages, { image });
    },
  },

  methods: {
    getStylesByDamage(damage) {
      return {
        left: `${damage.coordinateX}%`,
        top: `${damage.coordinateY}%`,
      };
    },
  },
};
</script>

