<template>
  <div class="DamageFooter">
    <div class="d-flex flex-wrap mb-4">
      <div class="d-flex col align-items-center justify-content-start mb-3">
        <DamagePointComponent />
        <div class="d-flex flex-column ml-2">
          <h2>
            {{ $t('views.vehicle_damage_report.damages') }}
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DamagePointComponent from './DamagePointComponent';

export default {
  name: 'ReportFooterComponent',
  components: {
    DamagePointComponent,
  },
};
</script>
