import get from 'lodash/get';

export const getVehicleModel = vehicle => {
  const brand = get(vehicle, 'model.brand.name');
  const model = get(vehicle, 'model.name');

  if (!brand && !model) {
    return '';
  }

  return brand ? `${brand} ${model}` : model;
};
