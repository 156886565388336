<script>
export default {
  name: 'DamagePointComponent',
};
</script>

<template>
  <div
    class="
      DamagePoint
      emobg-border-radius-pill emobg-border-color-danger-lighter
      emobg-background-color-danger
    "
  />
</template>
<style lang="scss">
  .DamagePoint {
    width: 36px;
    height: 36px;
    border-style: solid !important;
    border-width: 11px !important;
  }
</style>
