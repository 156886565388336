<template>
  <div class="DamageHeader px-4 pt-2 emobg-shadow-s">
    <Logo />
  </div>
</template>

<script>
import Logo from '@/components/Logo/Logo';

export default {
  name: 'ReportHeaderComponent',
  components: {
    Logo,
  },
};
</script>
